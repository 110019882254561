import { useEffect, useReducer, useMemo, useState, useRef, useContext } from 'react'
import {
  makeGetRequest,
  makePostRequest,
  makePatchRequest,
  makePutRequest,
  makeDeleteRequest
} from 'utils/api'
import cn from 'classnames'
import * as ACT from './actions'
import { reducer, initialState } from './reducer'
import { IoMdLock } from 'react-icons/io'
import {
  ModalContainer,
  Button,
  Alert,
  Banner,
  DataTableWrapper,
  FileIcon,
  FolderIcon,
  FolderIconFill,
  EmailFolderIcon,
  ShareFillIcon,
  ShareLineIcon
} from 'simple-core-ui'
import { Breadcrumbs } from './Breadcrumbs'
import { getNormalizedCellContent, viewAttachment, sortLabels } from './utils'
import { hasModule, openLink } from 'utils/helpers'
import { MODULE } from 'utils/constants'
import {
  resultsSerializer,
  toFilesData,
  toFoldersData,
  toFolderId,
  convertKeysToPath
} from './serializers'
import { serializeSharedWithUsers } from 'files/FileShare/serializer'
import s from './MatterAttachmentsTab.scss'
import swal from 'sweetalert'
import { MATTER_STATUS } from 'matters/constants'
import { Dropzone } from './Dropzone'
import { FaDownload } from 'react-icons/fa'
import {
  isFolder,
  getFolderId,
  getAttachmentLabels,
  getFileStatus,
  getFolderType,
  bitesToMb,
  getFileEmailId,
  getFileSize
} from './helpers'
import { CreateFolder } from './CreateFolder'
import difference from 'lodash/difference'
import {
  ROOT_PATH,
  FILE_STATUS_INFECTED,
  FILE_STATUS_CLEAN,
  FILE_STATUS_SCANNING,
  FILE_STATUS_UPLOADED,
  MAX_FILE_SIZE_ALLOWED,
  EMAIL_FOLDER_TYPE
} from './constants'
import { ActionsPopover } from './ActionsPopover'
import { useDispatch } from 'react-redux'
import APP_ACT from 'app/actions'
import { CategorySelect, LabelsSelect } from 'common/Selects'
import queryString from 'query-string'
import { TableLabels } from './TableLabels'
import { FileStatusIcon } from './FileStatusIcon'
import { RenameFile } from './RenameFile'
import { VersionUpload } from './VersionUpload'
import { useNavigate, useParams } from 'react-router-dom'
import { UploadProgress } from './UploadProgress'
import { AssociatedEmail } from './AssociatedEmail'
import { ExtensionImage } from 'common/FileUpload/ExtensionImage'
import FileShareModal from 'files/FileShare/FileShareModal'
import { DraftingSidebar } from './DocumentDrafting'
import { PiMagicWandFill } from 'react-icons/pi'
import { INITIAL_DRAFTING_CONTEXT } from './DocumentDrafting/constants'
import DocumentDraftingContext from 'matters/detail/context'

let generator

const newButtonProps = {
  hasNewDesign: true,
  isPrimary: true,
  isOutline: true,
  style: { padding: '10px 15px' }
}

const virusScanEnabled = window.serverContext.get('data_for_react')?.virus_scan_enabled

const isNativeDMS = hasModule(MODULE.NATIVE_DMS)

const MatterAttachmentsTab = ({
  domProps,
  hasVendorCollaboration = false,
  isMatterFileSharingEnabled = false
}) => {
  const navigate = useNavigate()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isExistingFileModalVisible, setIsExistingFileModalVisible] = useState(false)
  const [isOtherDirDupFileModalVisible, setIsOtherDirDupFileModalVisible] = useState(false)
  const [duplicatedFile, setDuplicatedFile] = useState(null)
  const [otherDirDuplicatedFile, setOtherDirDuplicatedFile] = useState(null)
  const [notSyncFlag, setNotSyncFlag] = useState(false)
  const [reshareFlag, setReshareFlag] = useState(false)
  const [reshareOption, setReshareOption] = useState(true)
  const [addVersionMode, setAddVersionMode] = useState(null)
  const [isRenameFileModalVisible, setIsRenameFileModalVisible] = useState(false)
  const [isRenameFolderModalVisible, setIsRenameFolderModalVisible] = useState(false)
  const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] = useState(false)
  const [isEditCategoryModalVisible, setIsEditCategoryModalVisible] = useState(false)
  const [isDeleteFileModalVisible, setIsDeleteFileModalVisible] = useState(false)
  const [isEditLabelsModalVisible, setIsEditLabelsModalVisible] = useState(false)
  const [isAddVersionModalVisible, setIsAddVersionModalVisible] = useState(false)
  const [isUploadProgressModalVisible, setIsUploadProgressModalVisible] = useState(false)
  const [uploadsFinishedNo, setUploadsFinishedNo] = useState(0)
  const [canCancelFilesArr, setCanCancelFilesArr] = useState([])
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false)
  const [isShareModalVisible, setIsShareModalVisible] = useState(false)
  const [sharedWithUsers, setSharedWithUsers] = useState([])
  const reduxDispatch = useDispatch()
  const {
    showDraftingSidebar,
    setShowDraftingPreview,
    setDraftingContext,
    setShowDraftingSidebar
  } = useContext(DocumentDraftingContext)
  const {
    attachments,
    params,
    isLoading,
    files,
    folder,
    folders,
    currentFolder,
    previewEnabled,
    file,
    versionFile,
    duplicatedFiles,
    filesToUpload,
    email,
    rootFileStructure,
    otherDirDuplicatedFiles
  } = state
  const { id: matterId } = useParams()
  const { matterStatus, canEdit } = domProps
  const hasActions = canEdit && [MATTER_STATUS.OPEN, MATTER_STATUS.DRAFT].includes(matterStatus)
  const data = isNativeDMS ? attachments.concat(folders) : attachments
  const hasAIDocumentDrafting = false

  const prevAttachmentsRef = useRef(attachments)

  const prevAttachments = prevAttachmentsRef.current

  const allowedFileStatusForSharing = [
    FILE_STATUS_CLEAN,
    ...(virusScanEnabled ? [] : [FILE_STATUS_UPLOADED])
  ]

  const getFolderItems = useMemo(
    () => async currentPath => {
      withLoading(async () => {
        const url = `/doc_management/native_docs/directories/matters/${matterId}/`
        const response = await makeGetRequest(url, {
          params: {
            ...(isNativeDMS && currentPath ? { directory: currentPath } : { flat: true })
          }
        })
        const {
          children,
          files,
          previewEnabled,
          id,
          root_id,
          path = ROOT_PATH,
          directory_type,
          canDownload,
          root_file_structure
        } = response.data

        dispatch({
          type: ACT.FETCH_MATTER_FOLDER_ITEMS,
          payload: {
            folders: toFoldersData(children),
            attachments: toFilesData(files),
            rootFileStructure: convertKeysToPath(root_file_structure),
            previewEnabled,
            currentFolder: {
              id: toFolderId(isNativeDMS ? id : root_id),
              path,
              type: directory_type,
              canDownload
            }
          }
        })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matterId]
  )

  useEffect(() => {
    prevAttachmentsRef.current = attachments
    if (prevAttachments?.length + attachments?.length === 1) {
      getFolderItems(currentFolder.path)
    }
  }, [attachments, getFolderItems, prevAttachmentsRef, prevAttachments, currentFolder])

  const errorsObj = useMemo(() => {
    const names = {}
    let duplicates = []
    let scanningInfected = []
    let fileSizeExceeded = false
    let duplicatedAttachmentNames = []
    let otherDirDuplicateNames = []

    if (!files.length)
      return {
        duplicatedFileNames: duplicates,
        fileSizeExceeded,
        scanningInfected
      }

    for (let file of files) {
      const attachment = attachments.find(a => a.fileName === file.name)
      const name = file.name
      if (typeof rootFileStructure === 'object' && currentFolder.path in rootFileStructure)
        delete rootFileStructure[currentFolder.path]

      if (attachment) {
        duplicatedAttachmentNames.push(attachment.fileName)
      }

      if (
        attachment &&
        [FILE_STATUS_SCANNING, FILE_STATUS_INFECTED].includes(attachment.fileStatus)
      ) {
        scanningInfected.push(file.name)
      }

      if (bitesToMb(file.size) > MAX_FILE_SIZE_ALLOWED) {
        fileSizeExceeded = true
      }
      if (names[name]) {
        names[name] = names[name] + 1
      } else {
        names[name] = 1
      }
      const processedFileNames = new Set()

      for (let folderPath in rootFileStructure) {
        const fileNames = rootFileStructure[folderPath]

        for (let fileName of fileNames) {
          const lowerCaseFileName = fileName.toLowerCase()
          if (!processedFileNames.has(lowerCaseFileName)) {
            if (file.name.toLowerCase() === lowerCaseFileName) {
              otherDirDuplicateNames.push({ name: file.name, folder_name: folderPath })
              processedFileNames.add(lowerCaseFileName)
            }
          }
        }
      }
    }

    duplicates = Object.keys(names).filter(name => {
      if (names[name] > 1) return name
    })

    dispatch({
      type: ACT.ADD_DUPLICATED_FILES,
      payload: {
        files: duplicatedAttachmentNames
      }
    })

    dispatch({
      type: ACT.ADD_OTHER_DIR_DUP_FILES,
      payload: {
        files: otherDirDuplicateNames
      }
    })

    return {
      duplicatedFileNames: duplicates,
      fileSizeExceeded,
      scanningInfected
    }
  }, [files, attachments, rootFileStructure, currentFolder])

  const duplicatedFileName = useMemo(() => {
    let isDuplicated = false

    if (!file.edited) return false

    for (let f of attachments) {
      if (f.name.toLowerCase() === file.name.toLowerCase() && f.id !== file.id) {
        isDuplicated = true
        break
      }
    }
    return isDuplicated
  }, [file, attachments])

  const duplicatedFolderName = useMemo(() => {
    let isDuplicated = false

    if (!folder.edited) return false

    for (let f of folders) {
      if (f.name.toLowerCase() === folder.name.toLowerCase()) {
        isDuplicated = true
        break
      }
    }
    return isDuplicated
  }, [folder, folders])

  const clearFiles = () => {
    if (!files.length) return
    dispatch({
      type: ACT.CLEAR_FILES,
      payload: {}
    })
  }

  const nextFile = generator => {
    setIsExistingFileModalVisible(false)
    setDuplicatedFile(null)
    setReshareFlag(false)
    setReshareOption(true)
    generator.next()
  }

  const otherDirNextFile = generator => {
    setIsOtherDirDupFileModalVisible(false)
    setOtherDirDuplicatedFile(null)
    generator.next()
  }

  const footerContent = (
    <div className={s.modalFooterContent}>
      <IoMdLock />
      <div className={s.textContainer}>
        <p>
          Users assigned to vendors who have reached the max of 5 shared files can&apos;t be
          selected. Contact customer success to upgrade.
        </p>
      </div>
    </div>
  )

  const startLoading = () => {
    dispatch({
      type: ACT.START_LOADING,
      payload: {}
    })
  }

  const stopLoading = () => {
    dispatch({
      type: ACT.STOP_LOADING,
      payload: {}
    })
  }

  const results = useMemo(() => {
    return resultsSerializer(data, hasVendorCollaboration, hasActions, isMatterFileSharingEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments, folders, params, previewEnabled, hasActions])

  const updateTable = params => {
    dispatch({
      type: ACT.UPDATE_TABLE_PARAMS,
      payload: {
        params
      }
    })
  }

  /*
    The current folder path is of type "//parent 1/parent 2/current folder".
    In order to generate the path for each breadcrumb we need to get from this path a structure like:
      "//" for root folder
      "//parent 1" for parent 1 folder
      "//parent 1/parent 2" for parent 2 folder
    The current folder doesn't have a link (the breadcrumb is not clickable)
  */
  const breadcrumbItems = useMemo(() => {
    if (currentFolder.path === ROOT_PATH && (folders.length || !isNativeDMS)) {
      return [{ title: 'All' }]
    } else {
      let pathItems = [
        {
          title: 'All',
          clickCb: () => {
            navigate(`?tab=files&directory=//#pane-attachments`)
            getFolderItems(ROOT_PATH)
          }
        }
      ]
      const items = currentFolder.path.split(ROOT_PATH)[1].split('/')
      items.forEach((item, index) => {
        const path = ROOT_PATH + items.slice(0, index + 1).join('/')

        if (index === items.length - 1) {
          pathItems.push({
            title: item
          })
        } else {
          pathItems.push({
            title: item,
            clickCb: () => {
              navigate(`?tab=files&directory=${encodeURIComponent(path)}#pane-attachments`)
              getFolderItems(path)
            }
          })
        }
      })
      return pathItems
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolder])

  const deleteAttachment = async attachmentId => {
    const attachment = getItem(attachmentId)

    if (attachment.previous_versions && attachment.previous_versions.length) {
      openDeleteFileModal(attachmentId)
      return
    }

    const deleteText =
      attachment.fileStatus === FILE_STATUS_INFECTED
        ? 'Do you want to delete it?'
        : 'Do you want to delete this file?'

    let willDelete = await swal({
      title:
        attachment.fileStatus === FILE_STATUS_INFECTED
          ? 'Malware was detected on this file.'
          : 'Delete file',
      text: deleteText,
      buttons: ['No', 'Yes'],
      icon: 'warning'
    })

    const id = isFolder(attachmentId) ? getFolderId(attachmentId) : attachmentId

    if (willDelete) {
      try {
        deleteAllVersions(id)
        swal('Deleted!', 'The file has been deleted', 'success')
      } catch (e) {
        swal('Sorry!', 'The file has not been deleted properly', 'error')
      }
    }
  }

  const deleteAllVersions = async id => {
    const response = await makePostRequest(
      `/doc_management/delete/matter_attachment/${matterId}/${id}/`
    )
    updateTable({ ...params, page: 1 })
    dispatch({
      type: ACT.DELETE_MATTER_ATTACHMENT,
      payload: {
        id: response.id
      }
    })
    setIsDeleteFileModalVisible(false)
  }

  const deleteVersion = async id => {
    const response = await makePutRequest(`/doc_management/native_docs/versions/${id}/`)

    updateTable({ ...params, page: 1 })
    dispatch({
      type: ACT.REPLACE_MATTER_ATTACHMENT,
      payload: {
        file: toFilesData([response.file])[0],
        id
      }
    })
    setIsDeleteFileModalVisible(false)
  }

  const deleteFolder = async folderId => {
    const folder = getItem(folderId)
    let willDelete = await swal({
      className: [s.confirmModal],
      title: `Are you sure you want to delete ${folder.name}?`,
      text: 'You will not be able to recover this folder and files.',
      buttons: ['No', 'Yes'],
      icon: 'warning'
    })

    if (willDelete) {
      try {
        const url = `/doc_management/native_docs/directories/matters/${matterId}/`
        const response = await makeDeleteRequest(url, {
          params: {
            directory_id: getFolderId(folderId),
            directory_path: folder.path
          }
        })
        updateTable({ ...params, page: 1 })
        dispatch({
          type: ACT.DELETE_MATTER_FOLDER,
          payload: {
            id: folderId
          }
        })
        swal('Deleted!', 'The folder has been deleted', 'success')
      } catch (e) {
        swal('Sorry!', 'The folder has not been deleted properly', 'error')
      }
    }
  }

  const withLoading = async callback => {
    startLoading()
    if (callback) {
      await callback()
    }
    stopLoading()
  }

  useEffect(() => {
    ;(() => {
      const queryParams = queryString.parse(window.location.search)
      if (queryParams.directory) {
        getFolderItems(queryParams.directory)
      } else {
        getFolderItems(currentFolder.path)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearFileName = () => {
    dispatch({
      type: ACT.CLEAR_FILE_NAME,
      payload: {}
    })
  }

  const clearFolderName = () => {
    dispatch({
      type: ACT.CLEAR_FOLDER_NAME,
      payload: {}
    })
  }

  const saveFolder = async () => {
    withLoading(async () => {
      const parent_id = currentFolder.path === ROOT_PATH ? 0 : getFolderId(currentFolder.id)
      const response = await makePostRequest(
        `/doc_management/native_docs/directories/matters/${matterId}/`,
        {
          name: folder.name,
          parent_id
        }
      )
      dispatch({
        type: ACT.ADD_FOLDER,
        payload: {
          folder: toFoldersData([response.data])[0]
        }
      })
      clearFolderName()
      setIsCreateFolderModalVisible(false)
    })
  }

  const clickRow = row => {
    const { id } = row
    if (isFolder(id)) {
      updateTable({ ...params, page: 1 })
      const folder = folders.find(f => f.id === id)
      navigate(`?tab=files&directory=${encodeURIComponent(folder.path)}#pane-attachments`)
      getFolderItems(folder.path)
    }
  }

  const getItem = id =>
    isFolder(id) ? folders.find(f => f.id === id) : attachments.find(a => a.id === id)

  const downloadAttachment = ({ id }) => {
    viewAttachment(getItem(id))
  }

  const downloadFolder = (id, path = '') => {
    let url = ''
    if (path) {
      url =
        path === ROOT_PATH
          ? `/doc_management/native_docs/directories/matters/download/${matterId}/`
          : `/doc_management/native_docs/directories/matters/download/${matterId}/?directory=${encodeURIComponent(
              path
            )}`
    } else {
      const folder = folders.find(f => f.id === id)
      url = `/doc_management/native_docs/directories/matters/download/${matterId}/?directory=${encodeURIComponent(
        folder.path
      )}`
    }
    openLink(url)
  }

  const openRenameFileModal = id => {
    setIsRenameFileModalVisible(true)
    dispatch({
      type: ACT.SELECT_FILE,
      payload: {
        id
      }
    })
  }

  const openRenameFolderModal = id => {
    setIsRenameFolderModalVisible(true)
    dispatch({
      type: ACT.SELECT_FOLDER,
      payload: {
        id
      }
    })
  }

  const selectFile = id => {
    dispatch({
      type: ACT.SELECT_FILE,
      payload: {
        id
      }
    })
  }

  const openEditCategoryModal = id => {
    setIsEditCategoryModalVisible(true)
    selectFile(id)
  }

  const openDeleteFileModal = id => {
    setIsDeleteFileModalVisible(true)
    selectFile(id)
  }

  const openEditLabelsModal = id => {
    setIsEditLabelsModalVisible(true)
    selectFile(id)
  }

  const openAddVersionModal = (id, versionFile) => {
    setIsAddVersionModalVisible(true)
    selectFile(id)
    dispatch({
      type: ACT.SELECT_VERSION_FILE,
      payload: {
        versionFile
      }
    })
  }

  const renameFile = () => {
    withLoading(async () => {
      try {
        const response = await makePatchRequest(
          `/doc_management/native_docs/rename_document/${file.id}/`,
          {
            name: file.name
          }
        )
        dispatch({
          type: ACT.RENAME_FILE,
          payload: {
            id: file.id,
            file
          }
        })
        reduxDispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Success',
            message: 'File successfully renamed',
            level: 'success'
          }
        })
      } catch (error) {
        if (error.response.status === 409) {
          setNotSyncFlag(true)
        }
      }
      clearFileName()
      setIsRenameFileModalVisible(false)
    })
  }

  const renameFolder = () => {
    withLoading(async () => {
      try {
        const parent_id = folder.path === ROOT_PATH ? 0 : getFolderId(folder.id)
        const response = await makePatchRequest(
          `/doc_management/native_docs/directories/matters/${matterId}/`,
          {
            name: folder.name,
            directory_id: getFolderId(folder.id),
            path: folder.path
          }
        )
        dispatch({
          type: ACT.RENAME_FOLDER,
          payload: {
            folderName: folder.name,
            id: folder.id,
            folder: toFoldersData([response.data])[0]
          }
        })
        reduxDispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Success',
            message: 'Folder successfully renamed',
            level: 'success'
          }
        })
      } catch (error) {
        if (error.response.status === 409) {
          setNotSyncFlag(true)
        }
      }
      clearFolderName()
      setIsRenameFolderModalVisible(false)
    })
  }

  const saveCategory = () => {
    withLoading(async () => {
      try {
        const response = await makePutRequest(
          `/doc_management/native_docs/update_upload/${file.id}/`,
          {
            category_id: file.category ? file.category.value : -1,
            label_ids: file.labels ? file.labels.map(l => l.value) : []
          }
        )
        dispatch({
          type: ACT.EDIT_CATEGORY,
          payload: {
            id: file.id,
            selectedCategory: file.category
          }
        })
        reduxDispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Success',
            message: 'Category successfully edited',
            level: 'success'
          }
        })
      } catch (error) {}

      setIsEditCategoryModalVisible(false)
    })
  }

  const saveLabels = () => {
    withLoading(async () => {
      try {
        const response = await makePutRequest(
          `/doc_management/native_docs/update_upload/${file.id}/`,
          {
            label_ids: file.labels ? file.labels.map(l => l.value) : [],
            category_id: file.category ? file.category.value : -1
          }
        )
        dispatch({
          type: ACT.EDIT_LABELS,
          payload: {
            id: file.id,
            selectedLabels: file.labels
          }
        })
        reduxDispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Success',
            message: 'Labels successfully edited',
            level: 'success'
          }
        })
      } catch (error) {}

      setIsEditLabelsModalVisible(false)
    })
  }

  const viewEmail = async id => {
    try {
      const emailId = getFileEmailId(attachments, id)
      const response = await makeGetRequest(`/manage/matters/email/?email=${emailId}`)
      setIsEmailModalVisible(true)
      dispatch({
        type: ACT.ADD_EMAIL,
        payload: {
          email: response.email[0]
        }
      })
    } catch (e) {
      reduxDispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Error',
          message: 'The email could not be found',
          level: 'error'
        }
      })
    }
  }

  const clearEmail = () => {
    setIsEmailModalVisible(false)
    dispatch({
      type: ACT.CLEAR_EMAIL,
      payload: {}
    })
  }

  const renderCustomAction = row => {
    const { id } = row
    const currentItem = getItem(id)
    const isInfected = currentItem.fileStatus === FILE_STATUS_INFECTED
    const disableDownload =
      [FILE_STATUS_SCANNING, FILE_STATUS_INFECTED].includes(currentItem.fileStatus) ||
      currentItem.size === 0
    if (
      currentItem.fileStatus === FILE_STATUS_SCANNING ||
      (matterStatus === MATTER_STATUS.CLOSED && isFolder(id))
    ) {
      return <span className={s.actionsPlaceholder}>...</span>
    }

    const folderType = isFolder(id) ? getFolderType(folders, id) : null

    return (
      <ActionsPopover
        matterId={matterId}
        currentItem={currentItem}
        currentFolder={currentFolder}
        isInfected={isInfected}
        rowId={id}
        isFolder={isFolder(id)}
        folderType={folderType}
        downloadAttachment={downloadAttachment}
        disableDownload={disableDownload}
        deleteAttachment={deleteAttachment}
        previewAttachment={previewAttachment}
        openRenameFileModal={openRenameFileModal}
        openRenameFolderModal={openRenameFolderModal}
        getFolderItems={getFolderItems}
        openEditCategoryModal={openEditCategoryModal}
        openEditLabelsModal={openEditLabelsModal}
        openAddVersionModal={openAddVersionModal}
        deleteFolder={deleteFolder}
        downloadFolder={downloadFolder}
        viewEmail={viewEmail}
        hasVendorCollaboration={hasVendorCollaboration}
        newTooltipDesignClass={s.newTooltipDesign}
        showOption={hasActions}
      />
    )
  }

  const promptExistingFile = file => {
    setIsExistingFileModalVisible(true)
    setDuplicatedFile(file)
  }

  const promptDuplicateFile = file => {
    setIsOtherDirDupFileModalVisible(true)
    setOtherDirDuplicatedFile(file)
  }

  const handleFilesUpload = () => {
    const existingFiles = []
    const otherDirDupFiles = []

    if (attachments.length) {
      files.forEach(file => {
        for (let attachment of attachments) {
          if (attachment.name === file.name) {
            existingFiles.push(file)
          }
        }
      })
    }
    const nonDuplicateFiles = difference(files, existingFiles)

    const processedFiles = new Set()

    files.forEach(file => {
      for (let folderPath in rootFileStructure) {
        var fileNames = rootFileStructure[folderPath]

        for (let fileName of fileNames) {
          const lowerCaseFileName = fileName.toLowerCase()
          if (!processedFiles.has(lowerCaseFileName)) {
            if (file.name.toLowerCase() === lowerCaseFileName) {
              otherDirDupFiles.push(file)
              processedFiles.add(lowerCaseFileName)
            }
          }
        }
      }
    })
    const newFiles = difference(nonDuplicateFiles, otherDirDupFiles)

    if (existingFiles.length) {
      generator = filesGenerator(existingFiles, newFiles, promptExistingFile)
      generator.next()
      clearFiles()
    } else if (otherDirDupFiles.length) {
      generator = filesGenerator(otherDirDupFiles, newFiles, promptDuplicateFile)
      generator.next()
      clearFiles()
    } else {
      toUploadFiles(newFiles)
      setIsUploadProgressModalVisible(true)
    }
  }

  function* filesGenerator(existingFiles, newFiles, promptCallback) {
    for (let file of existingFiles) {
      promptCallback(file)
      yield file
    }
    if (newFiles.length) {
      toUploadFiles(newFiles)
    }
    setIsUploadProgressModalVisible(true)
  }

  const toUploadFiles = (files, nextCallback) => {
    if (typeof nextCallback === 'function') {
      nextCallback(generator)
    }
    dispatch({
      type: ACT.ADD_FILES_TO_UPLOAD,
      payload: {
        files
      }
    })
  }

  const clearUploadedFiles = () => {
    dispatch({
      type: ACT.CLEAR_FILES_TO_UPLOAD,
      payload: {}
    })
    clearFiles()
    setIsUploadProgressModalVisible(false)
    setCanCancelFilesArr([])
    setUploadsFinishedNo(0)
  }

  const editCategory = category => {
    dispatch({
      type: ACT.CHANGE_CATEGORY_FOR_SELECTED_FILE,
      payload: {
        selectedCategory: category
      }
    })
  }

  const editLabels = labels => {
    dispatch({
      type: ACT.CHANGE_LABELS_FOR_SELECTED_FILE,
      payload: {
        selectedLabels: labels
      }
    })
  }

  const getFolderIcon = folderType => {
    //TODO: Icon for Email folder reuired from design
    if (hasVendorCollaboration) return <FolderIconFill className={s.folderIconFill} />
    switch (folderType) {
      case 'email':
        return <EmailFolderIcon />
      case 'regular':
      default:
        return <FolderIcon />
    }
  }

  const renderCell = ({ rowId, columnKey, content }) => {
    if (!content) {
      return '--'
    }

    if (columnKey === 'type') {
      if (!isFolder(rowId)) {
        return (
          <div className={s.iconContainer}>
            {hasVendorCollaboration ? <ExtensionImage file={{ path: content }} /> : <FileIcon />}
          </div>
        )
      } else {
        return <div className={s.iconContainer}>{getFolderIcon(getFolderType(folders, rowId))}</div>
      }
    } else if (columnKey === 'status') {
      if (!isFolder(rowId)) {
        return (
          <div className={s.iconContainer}>
            <FileStatusIcon fileStatus={getFileStatus(attachments, rowId)} id={rowId} />
          </div>
        )
      } else {
        return content
      }
    } else if (columnKey === 'name') {
      let fileLabels = []
      let visibleLabels = []
      if (!isFolder(rowId)) {
        fileLabels = getAttachmentLabels(attachments, rowId)
        visibleLabels = fileLabels.slice(0, 2)
      }

      return (
        <div className={s.iconContainer}>
          {isFolder(rowId) || getFileSize(attachments, rowId) === 0 ? (
            <span className={s.folderName}>{content}</span>
          ) : (
            <div className={s.labelsWrapper}>
              {isNativeDMS &&
              previewEnabled &&
              getFileStatus(attachments, rowId) === FILE_STATUS_CLEAN ? (
                <div className={s.previewLink} onClick={() => previewAttachment(rowId)}>
                  {content}
                </div>
              ) : (
                <div>{content}</div>
              )}
              {isNativeDMS && !hasVendorCollaboration ? (
                <TableLabels fileLabels={fileLabels} visibleLabels={visibleLabels} />
              ) : null}
            </div>
          )}
        </div>
      )
    } else if (columnKey === 'label') {
      if (!isFolder(rowId)) {
        const fileLabels = sortLabels(getAttachmentLabels(attachments, rowId))
        if (fileLabels.length) {
          return (
            <TableLabels
              fileLabels={fileLabels}
              visibleLabels={fileLabels.slice(0, 1)}
              tagRequired={false}
            />
          )
        }
      }
      return '--'
    } else if (columnKey === 'share') {
      if (
        !isFolder(rowId) &&
        allowedFileStatusForSharing.includes(getFileStatus(attachments, rowId))
      ) {
        if (content.is_shared) {
          return (
            <ShareFillIcon style={{ color: '#3D99FD' }} onClick={() => handleShareClick(rowId)} />
          )
        }
        return <ShareLineIcon onClick={() => handleShareClick(rowId)} />
      } else return '--'
    } else {
      return content
    }
  }

  const previewAttachment = async attachmentId => {
    try {
      const response = await makeGetRequest(`/doc_management/native_docs/preview/${attachmentId}`)
      openLink(response.previewURL)
    } catch (error) {
      window.store.dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const clearVersionFile = () => {
    setIsAddVersionModalVisible(false)
    dispatch({
      type: ACT.CLEAR_VERSION_FILE,
      payload: {}
    })
  }

  const cancelFileUpload = id => {
    dispatch({
      type: ACT.CANCEL_FILE_UPLOAD,
      payload: {
        id
      }
    })
  }

  const cancelAllFilesUpload = () => {
    canCancelFilesArr.forEach(fileSource => {
      fileSource.cancel()
    })
  }

  const handleShareClick = id => {
    const selectedFile = attachments.find(f => f.id === id)
    setSharedWithUsers(selectedFile.shared_with_users)
    selectFile(id)
    setIsShareModalVisible(true)
  }

  const closeShareModal = () => {
    setIsShareModalVisible(false)
  }

  const updateFileData = (fileId, shared_with_users) => {
    dispatch({
      type: ACT.EDIT_SHARED_WITH_USERS,
      payload: {
        id: fileId,
        sharedWithUsers: shared_with_users,
        isShared: Boolean(shared_with_users.length)
      }
    })
  }

  const cloneFile = file => new File([file], file.name, { type: file.type })

  const handleReshare = () => {
    let file
    switch (addVersionMode) {
      case 'EXISTING_FILE_MODAL':
        file = cloneFile(duplicatedFile)
        file.reshare = reshareOption
        toUploadFiles([file], nextFile)
        break
      case 'ADD_VERSION_MODAL':
        file = cloneFile(versionFile)
        file.reshare = reshareOption
        toUploadFiles([file])
        setIsUploadProgressModalVisible(true)
      default:
        break
    }
    setReshareOption(true)
    setReshareFlag(false)
  }

  const reshareModalContent = (
    <>
      <div className={s.subtitle}>
        Uploading this file will revoke sharing and remove all current collaborators&apos; access
        <br />
        Choose who to share the new version with :
      </div>
      <div className={s.flex}>
        <div
          className={cn(s.method, {
            [s.selected]: reshareOption
          })}
          onClick={() => setReshareOption(true)}
        >
          <p className={s.header}>
            <ShareFillIcon className={s.shareFillIcon} /> Past Collaborators
          </p>
          <span>
            Share new file version with the same collaborators who have access to the current file
            version
          </span>
        </div>
        <div
          className={cn(s.method, {
            [s.selected]: !reshareOption
          })}
          onClick={() => setReshareOption(false)}
        >
          <p className={s.header}>
            <ShareLineIcon className={s.shareLineIcon} /> New Collaborators
          </p>
          <span>Do not share new file version and add collaborators at a later time</span>
        </div>
      </div>
    </>
  )

  return (
    <>
      {notSyncFlag && (
        <Banner
          styles={{
            zIndex: 3,
            position: 'fixed',
            top: '60px',
            left: '90px',
            right: '40px'
          }}
          title="Data Sync Issue"
          message="The documents table is currently out of sync. Please refresh your page"
        />
      )}
      <section>
        {showDraftingSidebar && (
          <DraftingSidebar
            primaryActionCb={() => setShowDraftingPreview(true)}
            secondaryActionCb={() => setDraftingContext(INITIAL_DRAFTING_CONTEXT)}
          />
        )}
        {isRenameFolderModalVisible && (
          <ModalContainer
            title="Rename Folder"
            cancelText="Close"
            size="sm"
            cancelCb={() => {
              clearFolderName()
              setIsRenameFolderModalVisible(false)
            }}
            confirmText="Save"
            confirmCb={renameFolder}
            isDisabled={!folder.name.trim() || duplicatedFolderName || !folder.edited}
            hasNewButtons={hasVendorCollaboration}
            content={
              <CreateFolder
                folder={folder}
                dispatch={dispatch}
                isDuplicated={duplicatedFolderName}
              />
            }
          />
        )}
        {isRenameFileModalVisible && (
          <ModalContainer
            title="Rename File"
            cancelText="Close"
            size="sm"
            cancelCb={() => {
              clearFileName()
              setIsRenameFileModalVisible(false)
            }}
            confirmText="Save"
            confirmCb={renameFile}
            isDisabled={!file.name || duplicatedFileName || !file.edited}
            hasNewButtons={hasVendorCollaboration}
            content={
              <RenameFile file={file} dispatch={dispatch} isDuplicated={duplicatedFileName} />
            }
          />
        )}
        {isEditCategoryModalVisible && (
          <ModalContainer
            title="Edit Category"
            cancelText="Close"
            size="sm"
            cancelCb={() => {
              setIsEditCategoryModalVisible(false)
            }}
            confirmText="Save"
            confirmCb={saveCategory}
            hasNewButtons={hasVendorCollaboration}
            content={
              <CategorySelect value={file.category} onChange={value => editCategory(value)} />
            }
          />
        )}
        {isEditLabelsModalVisible && (
          <ModalContainer
            title="Edit Labels"
            cancelText="Close"
            size="sm"
            cancelCb={() => {
              setIsEditLabelsModalVisible(false)
            }}
            confirmText="Save"
            confirmCb={saveLabels}
            hasNewButtons={hasVendorCollaboration}
            content={<LabelsSelect value={file.labels} onChange={values => editLabels(values)} />}
          />
        )}
        {isDeleteFileModalVisible && (
          <ModalContainer
            title={
              file.fileStatus === FILE_STATUS_INFECTED
                ? 'Malware detected on the latest file version'
                : 'Delete File'
            }
            cancelText="Close"
            size="sm"
            cancelCb={() => {
              setIsDeleteFileModalVisible(false)
            }}
            confirmText="Delete Current Version"
            confirmCb={() => deleteVersion(file.id)}
            secondaryConfirmText="Delete All"
            secondaryConfirmCb={() => deleteAllVersions(file.id)}
            hasNewButtons={hasVendorCollaboration}
            content={
              <div>
                {file.fileStatus === FILE_STATUS_INFECTED
                  ? `Do you want to delete all previous versions of '${file.name}' or just the infected version?`
                  : `Do you want to delete the current version or all versions of '${file.name}'?`}
              </div>
            }
          />
        )}
        {isExistingFileModalVisible && (
          <ModalContainer
            title={`Duplicate File ${duplicatedFiles.findIndex(a => a === duplicatedFile.name) +
              1} of ${duplicatedFiles.length}`}
            cancelText="Skip"
            size="sm"
            cancelCb={() => nextFile(generator)}
            confirmText="Version"
            confirmCb={() => {
              let attachment = attachments.find(a => a.name === duplicatedFile.name)
              if (
                hasVendorCollaboration &&
                attachment &&
                allowedFileStatusForSharing.includes(attachment.fileStatus)
              ) {
                setAddVersionMode('EXISTING_FILE_MODAL')
                setReshareFlag(true)
                setReshareOption(true)
                setIsExistingFileModalVisible(false)
              } else {
                toUploadFiles([duplicatedFile], nextFile)
              }
            }}
            hasNewButtons={hasVendorCollaboration}
            hasCloseIcon={false}
            content={
              <Alert
                message={
                  <>
                    <b>{duplicatedFile.name}</b>
                    <span> already exists in this location. Would you like to version it?</span>
                  </>
                }
                status="error"
              />
            }
            shouldModalClose={false}
          />
        )}
        {reshareFlag && (
          <ModalContainer
            title="Before you upload this file"
            cancelText="Cancel"
            size="sm"
            cancelCb={() => nextFile(generator)}
            confirmText="Apply"
            confirmCb={() => handleReshare()}
            hasNewButtons={hasVendorCollaboration}
            content={reshareModalContent}
            shouldModalClose={false}
          />
        )}
        {isOtherDirDupFileModalVisible && (
          <ModalContainer
            title={`Duplicate File ${otherDirDuplicatedFiles.findIndex(
              a => a.name === otherDirDuplicatedFile.name
            ) + 1} of ${otherDirDuplicatedFiles.length}`}
            cancelText="Skip"
            size="sm"
            cancelCb={() => otherDirNextFile(generator)}
            confirmText="Yes"
            confirmCb={() => toUploadFiles([otherDirDuplicatedFile], otherDirNextFile)}
            hasNewButtons={hasVendorCollaboration}
            hasCloseIcon={false}
            content={
              <Alert
                message={
                  <>
                    <b> {otherDirDuplicatedFile.name} </b>
                    <span> already exists in the folder </span>
                    <b>
                      {otherDirDuplicatedFiles.find(a => a.name === otherDirDuplicatedFile.name)
                        .folder_name === '//'
                        ? 'root'
                        : otherDirDuplicatedFiles
                            .find(a => a.name === otherDirDuplicatedFile.name)
                            .folder_name.split('/')
                            .slice(-1)[0]}
                    </b>
                    <span>. Do you want to continue?</span>
                  </>
                }
                status="error"
              />
            }
            shouldModalClose={false}
          />
        )}
        {isAddVersionModalVisible && (
          <ModalContainer
            title="Add Version File"
            cancelText="Close"
            size="lg"
            cancelCb={clearVersionFile}
            confirmText="Upload"
            confirmCb={() => {
              if (hasVendorCollaboration && allowedFileStatusForSharing.includes(file.fileStatus)) {
                setAddVersionMode('ADD_VERSION_MODAL')
                setReshareFlag(true)
                setReshareOption(true)
              } else {
                toUploadFiles([versionFile])
                setIsUploadProgressModalVisible(true)
              }
              setIsAddVersionModalVisible(false)
            }}
            hasNewButtons={hasVendorCollaboration}
            isDisabled={!versionFile || versionFile.name !== file.name}
            content={<VersionUpload versionFile={versionFile} existingFile={file} />}
          />
        )}
        {isUploadProgressModalVisible && (
          <ModalContainer
            title={
              !filesToUpload.length || uploadsFinishedNo === filesToUpload.length
                ? 'Upload completed'
                : 'Upload currently in progress...'
            }
            cancelText="Abort All Uploads"
            size="md"
            cancelCb={() => {
              cancelAllFilesUpload()
              clearUploadedFiles()
            }}
            confirmText="Done"
            confirmCb={clearUploadedFiles}
            isDisabled={uploadsFinishedNo !== filesToUpload.length}
            hideCancelBtn={!filesToUpload.length || uploadsFinishedNo === filesToUpload.length}
            hasCloseIcon={false}
            hasNewButtons={hasVendorCollaboration}
            content={
              <UploadProgress
                currentFolder={currentFolder}
                setUploadsFinishedNo={() => setUploadsFinishedNo(count => count + 1)}
                dispatch={dispatch}
                attachments={attachments}
                files={filesToUpload}
                cancelFileUpload={cancelFileUpload}
                setCanCancelFilesArr={setCanCancelFilesArr}
                matterId={matterId}
              />
            }
          />
        )}

        {isEmailModalVisible && (
          <ModalContainer
            title=""
            cancelText="Close"
            size="lg"
            cancelCb={clearEmail}
            hasNewButtons={hasVendorCollaboration}
            content={<AssociatedEmail email={email} />}
          />
        )}
        <div className={s.attachmentsHeader}>
          <Breadcrumbs items={breadcrumbItems} />
          <div className={s.mainActions}>
            {isNativeDMS && currentFolder.canDownload && (
              <Button
                {...(hasVendorCollaboration ? newButtonProps : { isSecondary: true })}
                onClick={() => downloadFolder(currentFolder.id, currentFolder.path)}
              >
                Download Folder
              </Button>
            )}
            {isNativeDMS && hasActions && currentFolder.type !== EMAIL_FOLDER_TYPE && (
              <Button
                {...(hasVendorCollaboration ? newButtonProps : { isSecondary: true })}
                onClick={() => setIsCreateFolderModalVisible(true)}
              >
                Create Folder
              </Button>
            )}
            {hasAIDocumentDrafting ? (
              <Button
                {...newButtonProps}
                isPrimary
                isOutline
                onClick={() => setShowDraftingSidebar(true)}
              >
                <div>
                  <PiMagicWandFill
                    style={{ color: '#57C787', margin: '0 5px -8px 0', fontSize: '24px' }}
                  />
                  Draft With AI
                </div>
              </Button>
            ) : null}
            {isCreateFolderModalVisible && (
              <ModalContainer
                title="Create Folder"
                cancelText="Close"
                size="sm"
                cancelCb={() => {
                  clearFolderName()
                  setIsCreateFolderModalVisible(false)
                }}
                confirmText="Save"
                confirmCb={saveFolder}
                hasNewButtons={hasVendorCollaboration}
                isDisabled={!folder.name.trim() || duplicatedFolderName}
                content={
                  <CreateFolder
                    folder={folder}
                    dispatch={dispatch}
                    isDuplicated={duplicatedFolderName}
                  />
                }
              />
            )}
            {hasActions && currentFolder.type !== EMAIL_FOLDER_TYPE && (
              <ModalContainer
                testid="files_upload_modal"
                title="Upload Files"
                cancelText="Close"
                size="lg"
                cancelCb={clearFiles}
                confirmText="Upload"
                confirmCb={handleFilesUpload}
                hasCloseIcon={false}
                hasNewButtons
                isDisabled={
                  !files.length ||
                  errorsObj.duplicatedFileNames.length ||
                  errorsObj.fileSizeExceeded ||
                  errorsObj.scanningInfected.length
                }
                content={
                  <Dropzone
                    duplicatedFileNames={errorsObj.duplicatedFileNames}
                    scanningInfectedFileNames={errorsObj.scanningInfected}
                    files={files}
                    dispatch={dispatch}
                  />
                }
              >
                {openModal => (
                  <Button
                    testid="upload_files_btn"
                    isPrimary
                    onClick={openModal}
                    {...(hasVendorCollaboration
                      ? { hasNewDesign: true, style: { padding: '10px 15px' } }
                      : {})}
                  >
                    Upload Files
                  </Button>
                )}
              </ModalContainer>
            )}
            {isShareModalVisible && (
              <FileShareModal
                fileId={file.id}
                fileName={file.name}
                sharedWithUsers={serializeSharedWithUsers(sharedWithUsers)}
                closeShareModal={closeShareModal}
                customQueryParams={{ vrn: '_matter_file_sharing', m: matterId, dfi: file.id }}
                updateFileData={updateFileData}
                footerContent={footerContent}
              />
            )}
          </div>
        </div>
        <DataTableWrapper
          params={params}
          getNormalizedCellContent={getNormalizedCellContent}
          categories={[]}
          rows={results.rows}
          columns={results.columns}
          updateTable={updateTable}
          statusText=""
          panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
          className={s.attachmentsTable}
          clickRow={clickRow}
          downloadRow={downloadAttachment}
          isLoading={isLoading}
          hasActions
          customAction={canEdit && renderCustomAction}
          {...(!hasVendorCollaboration
            ? {
                actions: [
                  row => ({
                    icon: FaDownload,
                    tooltip: 'Download',
                    onClick: (event, row) => downloadAttachment(row),
                    disabled:
                      [FILE_STATUS_SCANNING, FILE_STATUS_INFECTED].includes(row.fileStatus) ||
                      row.size === 0,
                    condition: !isFolder(row.id)
                  })
                ]
              }
            : {
                alwaysShowActions: true
              })}
          renderCell={renderCell}
          pageSizeOptions={[
            { value: 10, label: '10' },
            { value: 25, label: '25' }
          ]}
          disableActionsEventPropagation={hasVendorCollaboration}
        />

        {attachments.length === 0 && folders.length === 0 && !isLoading && (
          <h2 className={s.noItems}>Start creating folders and organizing matter files.</h2>
        )}
      </section>
    </>
  )
}

export default MatterAttachmentsTab
