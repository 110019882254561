import { useEffect, useState, useMemo } from 'react'
import { ModalContainer, Button, useLoading, Stepper } from 'simple-core-ui'
import s from './EditCourtRuleModal.scss'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { makeGetRequest, makePatchRequest, makeDeleteRequest, makePostRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { format, isEqual } from 'date-fns'
import { combineDateAndTimeString, utcDate, timezoneDate } from 'utils/helpers'
import { useImmer, Updater } from 'use-immer'
import { toCourtRule } from '../serializers'
import { CourtRuleStep } from './CourtRuleStep'
import { ExtendedCourtRule, Rule, RuleToUpdate, CourtRuleEvent } from './types'
import { toRules, fromEvent } from './serializers'
import { EventChangesStep } from './EventChangesStep'

interface Props {
  toggleModal: () => void
  scopeName: string
  selectedCourtRuleId: number | null
  refreshTable: () => void
  baseUrl: string
  scopeId: string
}

const EditCourtRuleModal = ({
  toggleModal,
  scopeName,
  selectedCourtRuleId,
  refreshTable,
  baseUrl,
  scopeId
}: Props) => {
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [courtRule, setCourtRule]: [ExtendedCourtRule, Updater<ExtendedCourtRule>] = useImmer<
    ExtendedCourtRule
  >({} as ExtendedCourtRule)
  const [oldCourtRule, setOldCourtRule]: [ExtendedCourtRule, Updater<ExtendedCourtRule>] = useImmer<
    ExtendedCourtRule
  >({} as ExtendedCourtRule)
  const [activeStep, setActiveStep] = useState(1)
  const [additionalEvents, setAdditionalEvents] = useState<Rule[]>([])
  const [removedEvents, setRemovedEvents] = useState<number[]>([])
  const [eventsToUpdate, setEventsToUpdate] = useState<RuleToUpdate[]>([])
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [events, setEvents] = useState<Rule[]>([])
  const [isTimeRequired, setIsTimeRequired] = useState(false)

  const selectAllRows = (localEvents?: CourtRuleEvent[]) => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    setSelectedRows(allRowsSelected ? [] : (localEvents || events).map(t => t.id))
  }

  const fetchRule = async () => {
    try {
      const response = await withLoadingLocks(
        makeGetRequest(
          `/event-management/calendar_rules/court-rules/details/${selectedCourtRuleId}`
        )
      )
      const obj = {
        ...toCourtRule(response),
        events: response.events,
        time: [
          format(
            timezoneDate(response.trigger_date as string, response.jurisdiction.time_zone),
            'HH:mm'
          ),
          null
        ]
      }
      setCourtRule(obj)
      setOldCourtRule(obj)

      const courtRuleEvents = response.events.map((e: CourtRuleEvent) => ({
        id: e.id,
        name: e.name,
        description: e.description,
        date: e.start_date,
        prevDate: e.old_date
      }))

      setEvents(courtRuleEvents)
      selectAllRows(courtRuleEvents)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchRequirments = async (jurisdictionId: number, triggerId: number) => {
    try {
      const { is_time_required } = await withLoadingLocks(
        makeGetRequest(
          `/event-management/calendar_rules/compute-requirements?jurisdiction_id=${jurisdictionId}&trigger_id=${triggerId}`
        )
      )
      setIsTimeRequired(is_time_required)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchRule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!courtRule.jurisdiction || !courtRule.triggerEvent) return
    fetchRequirments(courtRule.jurisdiction?.value, courtRule.triggerEvent?.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courtRule.jurisdiction?.value, courtRule.triggerEvent?.value])

  const steps = useMemo(() => {
    return [
      {
        text: <span className={s.step}>Edit Court Rules</span>,
        onClick: () => {
          if (activeStep > 1) {
            setActiveStep(1)
          }
        }
      },
      {
        text: <span className={s.step}>Event Changes</span>
      }
    ]
  }, [activeStep])

  const saveChanges = async () => {
    try {
      const { should_update_events, updates, additions, deletions } = await withLoadingLocks(
        makePatchRequest(
          `/event-management/calendar_rules/court-rules/details/${selectedCourtRuleId}`,
          {
            name: courtRule.name,
            trigger_date:
              courtRule.triggerDate && courtRule.time
                ? utcDate(
                    combineDateAndTimeString(courtRule.triggerDate as Date, courtRule.time[0]),
                    courtRule.jurisdiction.timezone
                  )
                : ''
          }
        )
      )

      if (
        !should_update_events ||
        (courtRule.triggerDate &&
          isEqual(courtRule.triggerDate as Date, oldCourtRule.triggerDate as Date) &&
          courtRule.time?.[0] === oldCourtRule.time?.[0])
      ) {
        dispatch({
          type: 'PUSH_NOTIFICATION',
          payload: {
            title: 'Success',
            message: `${courtRule.name} successfuly updated`,
            level: 'success'
          }
        })

        toggleModal()
      }

      refreshTable()
      setActiveStep(2)

      if (additions.length) {
        setAdditionalEvents(toRules(additions, undefined, 'add'))
        setEvents([...events, ...toRules(additions, undefined, 'add')])
        setSelectedRows([...selectedRows, ...additions.map((event: Rule) => event.id)])
      }
      if (deletions.length) {
        setRemovedEvents(deletions)
        setEvents(
          events.map(event => {
            if (deletions.includes(event.id)) {
              return {
                ...event,
                type: 'delete'
              }
            } else {
              return event
            }
          })
        )
      }
      setEventsToUpdate(updates)
      const eventDateChangedUpdates = updates
        .filter((update: RuleToUpdate) => update.event_date_changed || update.do_not_recalculate)
        .map((update: RuleToUpdate) => update.id)

      if (eventDateChangedUpdates.length) {
        setSelectedRows(selectedRows.filter(id => !eventDateChangedUpdates.includes(id)))
        setAllRowsSelected(false)
      }
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const onBack = () => {
    setActiveStep(activeStep - 1)
  }

  const selectRow = (id: number) => {
    setAllRowsSelected(false)

    if (selectedRows.includes(id)) {
      setSelectedRows(prevSelectedRows => prevSelectedRows.filter(rowId => rowId !== id))
    } else {
      setSelectedRows(prevSelectedRows => [...new Set([...prevSelectedRows, id])])
    }
  }

  const updateEvents = async () => {
    try {
      if (removedEvents.length) {
        const selectedEvents = removedEvents.filter(eventId => selectedRows.includes(eventId))
        await withLoadingLocks(
          makeDeleteRequest(`${baseUrl}/events/bulk-operations/`, {
            data: { event_ids: selectedEvents }
          })
        )
      }
      if (additionalEvents.length) {
        const selectedEvents = additionalEvents.filter(event => selectedRows.includes(event.id))
        await withLoadingLocks(
          makePostRequest(`/event-management/calendar_rules/create-events/${scopeId}`, {
            events: selectedEvents.map(fromEvent),
            court_rule_name: courtRule.name,
            jurisdiction_system_id: courtRule.jurisdiction?.value,
            jurisdiction_name: courtRule.jurisdiction?.label,
            trigger_date: courtRule.triggerDate
              ? utcDate(courtRule.triggerDate as Date, courtRule.jurisdiction.timezone)
              : '',
            trigger_item_system_id: courtRule.triggerEvent?.value,
            trigger_item_name: courtRule.triggerEvent?.label,
            prefix: courtRule.prefix
          })
        )
      }
      if (eventsToUpdate.length) {
        const selectedEvents = eventsToUpdate.filter(event => selectedRows.includes(event.id))

        await withLoadingLocks(
          makePatchRequest(`/event-management/matters/${scopeId}/events/bulk-update/`, {
            events: selectedEvents.map(event => ({
              start_date: event.start_date,
              name: event.name,
              description: event.description,
              id: event.id
            }))
          })
        )

        dispatch({
          type: 'PUSH_NOTIFICATION',
          payload: {
            title: 'Success',
            message: `${selectedEvents.length} event(s) from ${courtRule.name} were successfuly updated`,
            level: 'success'
          }
        })
      }

      refreshTable()
      toggleModal()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const isPristine = () => {
    return (
      courtRule.name === oldCourtRule.name &&
      courtRule.triggerDate &&
      isEqual(courtRule.triggerDate as Date, oldCourtRule.triggerDate as Date) &&
      courtRule.time?.[0] === oldCourtRule.time?.[0]
    )
  }

  const isDisabled = () => {
    return (
      !courtRule.name ||
      !courtRule.triggerDate ||
      isPristine() ||
      (isTimeRequired ? !courtRule.time?.[0] || courtRule.time[0] === '00:00' : false)
    )
  }

  if (!courtRule.id) return null

  return (
    <ModalContainer
      hideButtons
      size="xl"
      cancelCb={toggleModal}
      content={
        <div className={s.container}>
          <div className={s.content}>
            {activeStep === 2 && (
              <div className={s.stepperWrapper}>
                <Stepper
                  orientation="horizontal"
                  automaticHeader={false}
                  activeStep={activeStep}
                  steps={steps}
                  displayIndex
                  hasNewDesign
                />
              </div>
            )}
            <h2 className={s.title}>Edit Court Rules</h2>
            <AiOutlineInfoCircle className={s.info} /> Court Rules sets are populated through{' '}
            <b>CalendarRules</b> a third party application
            {activeStep === 1 ? (
              <CourtRuleStep
                courtRule={courtRule}
                setCourtRule={setCourtRule}
                scopeName={scopeName}
                isTimeRequired={isTimeRequired}
              />
            ) : (
              <EventChangesStep
                events={events}
                eventsToUpdate={eventsToUpdate}
                selectedRows={selectedRows}
                allRowsSelected={allRowsSelected}
                selectAllRows={selectAllRows}
                selectRow={selectRow}
                timezone={courtRule.jurisdiction.timezone}
              />
            )}
          </div>
          <div className={s.footer}>
            {activeStep === 2 && (
              <Button
                style={{
                  padding: '8px 15px',
                  whiteSpace: 'nowrap',
                  position: 'relative',
                  bottom: 2
                }}
                onClick={onBack}
                isPrimary
                isOutline
                hasNewDesign
                isDisabled={false}
              >
                Back
              </Button>
            )}
            <div className={s.rightButtons}>
              <Button
                style={{
                  padding: '8px 15px',
                  whiteSpace: 'nowrap',
                  position: 'relative',
                  bottom: 2
                }}
                onClick={toggleModal}
                isPrimary
                isOutline
                hasNewDesign
              >
                Cancel
              </Button>
              {activeStep === 1 ? (
                <Button
                  style={{
                    padding: '8px 15px',
                    whiteSpace: 'nowrap',
                    position: 'relative',
                    bottom: 2
                  }}
                  onClick={saveChanges}
                  isPrimary
                  hasNewDesign
                  isDisabled={isDisabled()}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  style={{
                    padding: '8px 15px',
                    whiteSpace: 'nowrap',
                    position: 'relative',
                    bottom: 2
                  }}
                  onClick={updateEvents}
                  isPrimary
                  hasNewDesign
                  isDisabled={selectedRows.length === 0}
                >
                  Update {selectedRows.length} Event(s)
                </Button>
              )}
            </div>
          </div>
        </div>
      }
    />
  )
}

export default EditCourtRuleModal
