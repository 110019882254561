import { CSSProperties, useContext, useMemo, useState } from 'react'
import s from './DraftingContextForm.scss'
import { FaBookReader } from 'react-icons/fa'
import { MdHistory } from 'react-icons/md'
import {
  Button,
  CharLimitInput,
  ModalContainer,
  OutsideClickContainer,
  useLoading
} from 'simple-core-ui'
import { SAMPLE_PROMPTS } from '../constants'
import { PromptsList } from '../PromptsList'
import { DraftingContextState, PromptsHistoryItem, PromptsListItem } from '../types'
import { makeGetRequest } from 'utils/api'
import { serializePromptsHistory } from '../serializer'
import DocumentDraftingContext from 'matters/detail/context'

interface Props {
  primaryActionCb: () => void
  secondaryActionCb: () => void
}

const CONTEXT_PLACEHOLDER =
  "Ensure the drafting context aligns with your chosen document type (if applicable). For instance, if you select 'NDA' as the type, specify its purpose and special clauses in the drafting context. Inconsistent context and document type may result in imprecise drafts."

const promptListStyles: CSSProperties = {
  right: '220px',
  position: 'absolute'
}

const DraftingContextForm = ({ primaryActionCb, secondaryActionCb }: Props) => {
  const [showSamplePrompts, setShowSamplePrompts] = useState(false)
  const [showPromptHistory, setShowPromptHistory] = useState(false)
  const [samplePrompts] = useState(SAMPLE_PROMPTS)
  const [promptsHistoryItems, setPromptsHistoryItems] = useState<PromptsHistoryItem[]>([])
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [loading, withLoadingLocks] = useLoading()

  const {
    draftingContext,
    setDraftingContext,
    showDraftingPreview,
    setShowDraftingPreview,
    showDraftingSidebar,
    setShowDraftingSidebar
  } = useContext<DraftingContextState>(DocumentDraftingContext)

  const fetchPromptsHistory = async () => {
    const response = await withLoadingLocks(
      makeGetRequest(`/doc_management/native_docs/doc_drafting/prompt/`)
    )
    setPromptsHistoryItems(serializePromptsHistory(response))
  }

  const setPrompt = (promptText: string) => {
    setDraftingContext({ ...draftingContext, text: promptText })
  }

  const samplePromptsList = useMemo(
    () =>
      samplePrompts.map((p: string) => ({
        ...draftingContext,
        text: p
      })) as PromptsListItem[],
    [draftingContext]
  )

  return (
    <>
      <label className={s.title}>{`${
        showDraftingPreview ? 'Preview' : 'Draft New'
      } Document`}</label>
      <div>
        <label className={s.fieldTitle}>
          Drafting Context<span className={s.required}>*</span>
        </label>
        <CharLimitInput
          value={draftingContext.text}
          placeholder={CONTEXT_PLACEHOLDER}
          onChangeCb={e => setPrompt(e.target.value)}
          maxLength={2500}
          dynamicCharCalculation
          style={{
            height: '200px'
          }}
          customErrorMessage="Maximum character limit reached"
          type="textarea"
          footer={
            <div className={s.contextFooter}>
              <div
                className={s.buttonText}
                onClick={() => setShowSamplePrompts(!showSamplePrompts)}
              >
                <FaBookReader
                  style={{ marginRight: '5px', marginBottom: '-4px', fontSize: '20px' }}
                />
                <span>View Sample Prompts</span>
              </div>
              <OutsideClickContainer closeComponent={() => setShowSamplePrompts(false)}>
                {showSamplePrompts && (
                  <PromptsList
                    styles={promptListStyles}
                    prompts={samplePromptsList}
                    setPrompt={setPrompt}
                  />
                )}
              </OutsideClickContainer>
              <div
                className={s.buttonText}
                onClick={() => {
                  setShowPromptHistory(!showPromptHistory)
                  fetchPromptsHistory()
                }}
              >
                <MdHistory style={{ margin: '0px 5px -6px 10px', fontSize: '24px' }} />
                <span>View Prompt History</span>
              </div>
              <OutsideClickContainer closeComponent={() => setShowPromptHistory(false)}>
                {showPromptHistory && (
                  <PromptsList
                    styles={{
                      right: '20px',
                      ...(!promptsHistoryItems.length ? { height: '100px' } : {})
                    }}
                    prompts={promptsHistoryItems}
                    setPrompt={setPrompt}
                    isHistory
                    isLoading={loading}
                  />
                )}
              </OutsideClickContainer>
            </div>
          }
        />
      </div>
      <div className={s.footer}>
        <Button
          onClick={secondaryActionCb}
          isPrimary
          isOutline
          hasNewDesign
          isDisabled={
            (showDraftingPreview && !draftingContext.text) ||
            (!showDraftingPreview && !draftingContext.text)
          }
          className={s.button}
        >
          {showDraftingPreview ? 'Redraft' : 'Clear All'}
        </Button>
        <Button
          onClick={() => {
            showDraftingSidebar && !draftingContext.text
              ? setShowDraftingSidebar(false)
              : setShowCancelModal(true)
          }}
          isPrimary
          isOutline
          hasNewDesign
          className={s.button}
        >
          Cancel
        </Button>
        <Button
          onClick={primaryActionCb}
          isPrimary
          hasNewDesign
          isDisabled={!showDraftingPreview && !draftingContext.text}
          className={s.button}
        >
          {showDraftingPreview ? 'Save' : 'Draft'}
        </Button>
      </div>
      {showCancelModal && (
        <ModalContainer
          title="Cancel"
          size="sm"
          confirmText={showDraftingPreview ? 'Save' : 'Yes'}
          cancelText={showDraftingPreview ? "Don't Save" : 'No'}
          confirmCb={() => {
            showDraftingSidebar ? setShowDraftingSidebar(false) : primaryActionCb()
            setShowCancelModal(false)
          }}
          cancelCb={() => {
            showDraftingPreview && setShowDraftingPreview(false)
            setShowCancelModal(false)
          }}
          content={
            showDraftingPreview
              ? "Do you want to save this document? Your changes will be lost if you don't save them. "
              : 'Are you sure you want to cancel? All changes will be lost.'
          }
          hasNewButtons
        />
      )}
    </>
  )
}

export default DraftingContextForm
